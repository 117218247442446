import * as React from 'react';
import { useState, useEffect } from 'react';
import MuseMatterport from '../images/muse-matterport-screenshot.jpg';
import Layout from '../components/Layout';
import MuseDentalClinic1 from '../images/muse-clinic-2.jpg';
import AirPurifier from '../images/air-purifier.jpg';
import MuseDentalInfectionControl from '../images/muse-dental-infection-control.jpg';
import DigitalScanners from '../images/services-digital-scanners.png'
import AppointmentCTA from '../components/AppointmentCTA';
import SEO from '../components/Seo';

const Clinic = () => {

    const [showMatterport, setShowMatterport] = useState(false);

    return(
        <Layout>
             <SEO title="Muse Dental - Clinic" description="Our clinic is thoroughly modern with the latest dental equipment. We pride ourselves on providing the highest level of patient comfort."/>
            <header>
                <div className="relative bg-muse-yellow">
                    <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                        <h1 className="text-4xl font-medium tracking-tight text-white sm:text-5xl lg:text-6xl text-center">Our Clinic</h1>
                    </div>
                </div>
            </header>


            <section>
                <div className="relative bg-muse-teal">
                {showMatterport ? 
                    <div className="flex justify-center aspect-w-16 aspect-h-9">
                    <iframe src='https://my.matterport.com/show/?m=Jq9VXkwytAQ' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
                    </div> 
                    :
                    <div>
                    <div className="h-56 bg-muse-teal sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                        <img className="w-full h-full object-cover" src={MuseMatterport} alt=""/>
                    </div>
                    <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                        <div className="md:ml-auto md:w-1/2 md:pl-10">
                        <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
                        
                        </h2>
                        <p className="mt-2 text-white text-3xl font-medium tracking-tight sm:text-4xl">
                        A 3D Virtual Tour of our Modern Dental Office
                        </p>
                        <p className="mt-3 text-lg text-gray-300">
                            Have an appointment? Get familiar with our office before your visit with our interactive 3D office walk through below.
                        </p>
                        <div className="mt-8">
                            <div className="inline-flex rounded-md shadow">
                            <button onClick={() => setShowMatterport(true)} className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-sm text-gray-900 bg-white hover:bg-gray-50">
                                View 3D Tour
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    }
                </div>
            </section>

            <section>
            <div className="py-16 bg-white overflow-hidden">
                <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">

                    <div className="relative mt-12 lg:mt-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                        <div className="relative">
                            <h3 className="text-2xl font-extrabold text-muse-teal tracking-tight sm:text-3xl">
                            Comfort & Convenience
                            </h3>
                            <p className="mt-3 text-lg text-gray-500">
                            We want to make your experience with Muse Dental as pleasant as possible. 
                            Everything from easy online booking, to the wondefully designed environment inside our office ensures you and your teeth leave feeling refreshed.
                            </p>

                            <dl className="mt-10 space-y-10">
                            <div className="relative">
                                <dt>
                                <p className="text-lg leading-6 font-bold text-muse-teal">Online Booking & Digital Reminders</p>
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    Easily book your appointment online and be reminded when your appointment is coming up. You can see what appointments we have available and book a time and date that suits you.
                                </dd>
                            </div>

                            <div className="relative">
                                <dt>
                                <p className="text-lg leading-6 font-bold text-muse-teal">TV's</p>
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    You can watch your favourite Netflix show during your appointment. Maybe you can suggest some good ones to us as well.
                                </dd>
                            </div>

                            <div className="relative">
                                <dt>
                                <p className="text-lg leading-6 font-bold text-muse-teal">Topical Gels</p>
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    We use high strength numbing gel to ensure you don't feel the pinch during your appointment.
                                </dd>
                            </div>
                            </dl>
                        </div>

                        <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                            <img className="relative mx-auto h-150 w-full object-cover" src={MuseDentalClinic1} alt="Muse Dental Clinic office chair"/>
                        </div>
                    </div>

                    <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8">
                        <div className="relative">
                            <h3 className="text-2xl font-extrabold text-muse-teal tracking-tight sm:text-3xl">
                            Air Purification
                            </h3>
                            <p className="mt-3 text-lg text-gray-500">
                            We take air quality seriously at Muse Dental. That's why we've made the significant investment of purchasing hospital-grade air purifiers, and placing them in the lobby and in EVERY treatment room.
                            </p>

                            <p className="mt-3 text-base text-gray-500">
                            They run continuously, cleaning our clinic air of any particulates and odours, and can scrub an entire treatment room's air volume eighteen times per hour.
                            </p>

                            <p className="mt-10 text-xl text-gray-800">
                            We selected the IQAir series for a variety of reasons:
                            </p>

                            <ul className="mt-3 space-y-3 text-gray-500">
                                <li>Multiple awards and selections: Chosen by the US Olympic Committee for athlete living and training quarters during the 2008 games in Beijing. American Lung Association educational partner. 4x Consumer's Digest Premium Best Buy winner. Finnish Institute of Occupational Health best-in-class Air Purifier. </li>
                                <li>But perhaps most notably, during the SARS coronavirus outbreak of 2003, the Hong Kong Hospital Authority tested numerous air control options that would help reduce the spread of the SARS virus and make for a safer and cleaner environment. </li>
                                <li>Ultimately, the HKHA selected the IQAir HealthPro Plus air purifier to be placed in over 150 of the hospitals and healthcare facilities it manages. This is the same unit we have selected for use here at Muse Dental. </li>
                            </ul>

                            <p className="mt-10 text-xl text-gray-800">
                            Effectiveness:
                            </p>

                            <p className="mt-3 text-base text-gray-500">
                            Most air filters meet the HEPA standard of filtering 99.95% of particles down to 0.3 microns. Ultrafine particles like some dust allergens (~0.25 microns), dander (~0.1 microns), smoke (~0.01 microns), and viruses (~0.005 microns) are not filtered by regular HEPA filters. 
                            </p>
                            <p className="mt-3 text-base text-gray-500">
                            But lQAir's HyperHEPA filtration is tested and certified by an independent third-party lab to effectively filter 99.5% of harmful ultrafine pollution particles down to 0.003 microns in size! This is 100 times smaller than what is achieved with ordinary HEPA filtration technology and 10 times smaller than a typical virus.
                            </p>

                            <p className="mt-3 text-base text-gray-500">
                            Swiss-made and tested: IQAir has a long-standing history of high-end design, innovation, precision, and reliability. Refined over a half century of business, their quality control measures include individually testing each unit and hand-signing a Certificate of Performance before it ships from their manufacturing facility in Switzerland.
                            </p>

                        </div>

                        <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                            <img className="relative mx-auto h-full w-full" src={AirPurifier} alt="Muse Dental Air Purification"/>
                        </div>
                    </div>

                    <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                        <div className="relative">
                            <h3 className="text-2xl font-extrabold text-muse-teal tracking-tight sm:text-3xl">
                            Infection Control
                            </h3>
                            <p className="mt-2 text-lg text-gray-500">
                                We have the highest standards for infection control. Our equipment is sterilized in medical grade steam autoclaves. All surfaces are completely wiped down after each appointment and we use DryShield and Purvac to reduce droplet splatter.
                            </p>

                        </div>

                        <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                            <img className="relative mx-auto h-150 w-full object-cover" src={MuseDentalInfectionControl} alt="Muse Dental Infection Control"/>
                        </div>
                    </div>

                    <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                        <div className="relative">
                            <h3 className="text-2xl font-extrabold text-muse-teal tracking-tight sm:text-3xl">
                            Digital Intraoral Scans and Impressions
                            </h3>
                            <p className="mt-2 text-lg text-gray-500">
                            With our iTero Element Scanner, we can digitally capture a detailed 3D model of your teeth and gums. Not only is this process far more comfortable than the old putty based impressions, but it’s faster and can offer a superior clinical endpoint. 
                            </p>

                        </div>

                        <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                            <img className="relative mx-auto h-160 w-full object-cover" src={DigitalScanners} alt="Muse Dental Digital Scanners"/>
                        </div>
                    </div>


                    
                </div>
                </div>
            </section>

            <AppointmentCTA/>

        </Layout>
    )
}

export default Clinic;